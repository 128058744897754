import config from 'data/config/config';
import { DATA_TYPE_EVENTS } from 'data/config/dataConfig';

import isEqual from 'lodash.isequal';

import * as UserDataWsNodeBackend from 'src/core/webservices/nodebackend/UserDataWS';
import * as UserDataWsTaiga from 'src/core/webservices/taiga/UserDataWS';
import { TAIGA, NODEBACKEND } from 'src/core/webservices/WsProviders';
import { WS_ERRORS } from 'src/core/webservices/WS_ERRORS';
import { getToken } from 'src/core/login/LoginPersistence';
import { getBindedActions } from 'src/store/bindedActions';
import { getParticipantsByIds } from 'src/core/participants/ParticipantsService';
import { isSessionValid } from 'src/core/login/LoginService';
import { findOne } from 'src/core/query/Query';

const LOCALSTORAGE_USER_PROFILE_KEY = 'userData';

const LOG_PREF = '[UserDataService] ';

export function getUserData() {
  return JSON.parse(window.localStorage.getItem(LOCALSTORAGE_USER_PROFILE_KEY));
}

export function setUserData(value) {
  window.localStorage.setItem(LOCALSTORAGE_USER_PROFILE_KEY, JSON.stringify(value));
}

export function clear() {
  window.localStorage.removeItem(LOCALSTORAGE_USER_PROFILE_KEY);
  getBindedActions().userDataUpdated(null);
}

export const isUserDataEnoughSet = (profile) =>
  !!(
    profile &&
    profile.firstName &&
    profile.lastName &&
    // && profile.title
    // && profile.organisation
    profile.position &&
    profile.email &&
    profile.phone
  );

export function remoteSaveUserData(newFields, updateRemote, cb) {
  // There is no TAIGA server-side log out. So as long as the cookie is valid, the app
  // can still execute requests on the backend, so it is needed to prevent WS calls
  if (isSessionValid() !== true) {
    cb({ error: WS_ERRORS.AUTH });
  }

  let currentUserData = getUserData();
  if (currentUserData === null) {
    currentUserData = {};
  }

  function next() {
    // Refresh full user data
    // refreshUserDataFromAPI()
    // Etienne suggestion is to update user data only locally:
    updateUserDataFromApiData(newFields);

    if (typeof cb === 'function') {
      cb(null);
    }
  }

  // remote update
  if (!updateRemote) {
    next();
  } else {
    // switch (config.USER_DATA.PROVIDER) {
    //   case TAIGA:
    //     UserDataWsTaiga.saveUserData(newFields, (error) => {
    //       if (error) {
    //         cb(error);
    //       } else {
    //         next();
    //       }
    //     });
    //     break;

    //   default:
    //     console.error(`${LOG_PREF}Unexpected web service provider: ${config.USER_DATA.PROVIDER}`);
    // }
  }
}

/**
 * Remove empty properties (to avoid overiding values set by the user)
 * @param  {object} userData
 * @return {object}
 *
function cleanData(userData) {
    let cleanedUserData = {};
    Object.keys(userData).forEach(function(key) {
        let value = userData[key];
        if (value !== null && typeof value !== 'undefined' && value !== '') {
            cleanedUserData[key] = value;
        }
    });
    return cleanedUserData;
} */

/**
 * @param  {object} userData
 */
export function updateUserDataFromApiData(userData) {
  const oldUserData = getUserData(); // get parsed data from localStorage

  // transform object like localStorage because of JSON.parse behavior
  const stringUserData = JSON.stringify(userData);
  const cleanUserDataToCompare = JSON.parse(stringUserData);
  // --------

  // lodash.isEqual: deep compare Objects between parsed Objects
  if (!isEqual(oldUserData, cleanUserDataToCompare)) {
    const newUserData = { ...oldUserData, ...cleanUserDataToCompare };
    setUserData(newUserData); //
    getBindedActions().userDataUpdated(newUserData);
  }

  // AD-6 - import favorites from user profile data
  if (Array.isArray(userData.events)) {
    userData.events.forEach(function(ev) {
      const event = findOne([(item) => item.original_id === ev.id], DATA_TYPE_EVENTS);
      if (event) {
        getBindedActions().toggleFavorite(event.id, DATA_TYPE_EVENTS, false);
      }
    });
  }
}

export function isItemRelatedToUserData(id, dataType) {
  const userData = getUserData();
  if (userData) {
    if (typeof id === 'string') {
      id = parseInt(id, 10);
    }

    switch (dataType) {
      case DATA_TYPE_EVENTS:
        if (Array.isArray(userData.events)) {
          const event = findOne([(item) => item.id === id], DATA_TYPE_EVENTS);
          if (event) {
            return !!userData.events.find((userEvent) => userEvent.id === event.original_id);
          }
        }
        break;

      default: // for linter
    }
  }
  return false;
}

/**
 * Retrieve profile information from token
 * @param  {string} token
 */
export function refreshUserDataFromAPI() {
  // There is no TAIGA server-side log out. So as long as the cookie is valid, the app
  // can still execute requests on the backend, so it is needed to prevent WS calls
  if (isSessionValid() !== true) {
    return;
  }

  /* switch (config.USER_DATA.PROVIDER) {
    case NODEBACKEND:
      const sessionToken = getToken();
      if (!sessionToken) {
        console.error(
          `${LOG_PREF}Cannot get profile information from API because session 'token' is missing`
        );
        return;
      }
      UserDataWsNodeBackend.getUserData(sessionToken, getUserDataCallback);
      break;

    case TAIGA:
      UserDataWsTaiga.getUserData(getUserDataCallback);
      break;

    default:
      return null; //console.error(`${LOG_PREF}Unexpected web service provider: ${config.USER_DATA.PROVIDER}`);
  } */
}

function getUserDataCallback(errorCode, userdata) {
  if (!errorCode && userdata) {
    updateUserDataFromApiData(userdata);
  }
}

export function getContactedParticipants(cb) {
  const userData = getUserData();
  if (!userData) {
    cb({});
    return;
  }
  return getParticipantsByIds(userData.contactedParticipants || [].map((c) => c.contactId), cb);
}
