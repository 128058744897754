import config from '../../../data/config/config';
import { VISITOR_PROFILE, DESK_PROFILE } from '../../../data/config/profiles';
import { DATA_TYPE_EXHIBITORS, DATA_TYPE_USERS } from '../../../data/config/dataConfig';
import { DEFAULT_IMAGE } from 'data/config/ficheConfig';

import { LIST_PAGE_KEY } from 'src/pages/pagesKeys';
import { getBindedActions } from 'src/store/bindedActions';

import { get as getLabels } from 'src/core/Lang';
import { set as setProfile } from 'src/core/Profile';
import showConfirmModal from 'src/core/util/showConfirmModal';
import showPromptModal from 'src/core/util/showPromptModal';
import * as Query from 'src/core/query/Query';
import * as LoginService from 'src/core/login/LoginService';
import * as DataAssetsUtil from 'src/core/data-and-assets/DataAssetsUtil';
import { clear as clearPersistedData } from 'src/core/data-and-assets/DbPersistence';

import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels';

import * as TexShowPersistence from './TexShowPersistence';
import * as FavoritesPersistence from 'src/core/favorites/Favorites';
import * as NotesPersistence from 'src/core/notes/Notes';
import * as UserDataService from 'src/core/user-data/UserDataService';
import fetchHelper, { HEADERS } from 'src/core/util/FetchHelper';
import { get as getProfile } from 'src/core/Profile';
import * as Updater from 'src/core/data-and-assets/Updater';
import { isNonNullObject } from 'src/store/reducers/utils';
import { isSessionValid } from 'src/core/login/LoginService';

export function onUserLogIn(data) {
  if (data) {
    let userData = config.texShow.userData(data);
    getBindedActions().setLoginStatus(true, userData, null);
    getBindedActions().userDataUpdated(userData);
    TexShowPersistence.setUserData(userData);
  }
}

export function checkCodeAuthenticate(loggedIn, idSelected, newContact = null) {
  showPromptModal({
    title: getLabels().texShow.titleCodeRequired,
    yesBtnLabel: getLabels().common.confirm,
    noBtnLabel: getLabels().common.cancel,
    validateCb: async (input) => {
      if (typeof input !== 'string') {
        // user probably cancelled the action (modal discarded)
        // ignore
      } else if (TexShowPersistence.checkAuthentificateCode(input)) {
        const existingContact = loggedIn ? getContact(idSelected) : null;
        getBindedActions().setChoosedContact(loggedIn, newContact || existingContact, null);
        setProfile(loggedIn ? VISITOR_PROFILE : DESK_PROFILE);
        global.isAuthentificateWithCode = loggedIn;
        getBindedActions().navigate(LIST_PAGE_KEY, {
          inputs: [{ dataType: loggedIn ? DATA_TYPE_EXHIBITORS : DATA_TYPE_USERS }],
        });
        if (loggedIn === true) {
          window.location.href = window.location.href.replace('%22', '"');
          TexShowPersistence.setIsUpdated(true);
        } else {
          LoginService.logOut();
        }
      } else {
        global.isAuthentificateWithCode = null;
        getBindedActions().showNotification({
          message: getLabels().profile.invalidCode,
          level: NotificationLevels.ERROR,
        });
      }
    },
    noCb: global.noop,
  });
  return global.isAuthentificateWithCode || null;
}

export function addNewContact() {
  openContactForm(null);
}

export function openContactForm(contact) {
  getBindedActions().showFormModal(
    {
      title: getLabels().contacts.form.title,
      subtitle: getLabels().contacts.form.subTitle,
      labels: {
        sendBtnLabel: getLabels().common.selectedUser,
        cancelBtnLabel: getLabels().common.cancel,
        clearBtnLabel: getLabels().common.delete,
      },
      fields: [
        {
          type: 'hidden',
          subType: 'text',
          name: 'id',
          value: contact ? contact.id : 999,
          label: '',
        },
        {
          type: 'hidden',
          subType: 'text',
          name: 'scanDate',
          value: Date.now(),
          label: '',
        },
        /*     {
          type: 'image',
          subType: '',
          storageType: 'local',
          name: 'profilePicture',
          defaultImageUrl: DataAssetsUtil.getUrl(`/${DEFAULT_IMAGE[DATA_TYPE_USERS]}`),
          specialPermission: {
            text: getLabels().contacts.photoPermission,
            yesBtnLabel: getLabels().common.ok,
            noBtnLabel: getLabels().common.cancel,
          },
          value: contact ? contact.photo : '',
          label: getLabels().contacts.form.fields.profilePicture.label,
          mandatory: false,
          validation: {
            type: 'image',
            params: {
              checkCrop: true,
              //mime_type : ['jpeg', 'png']
            },
            validationMessage: getLabels().contacts.form.fields.profilePicture.validationMessage,
            alertMessage: getLabels().contacts.form.fields.profilePicture.alertMessage,
          },
        }, */
        {
          type: 'inputModal',
          subType: 'text',
          name: 'civility',
          mandatory: false,
          value: contact ? contact.civility : '',
          label: getLabels().contacts.form.fields.title.label,
        },
        {
          type: 'inputModal',
          subType: 'text',
          name: 'firstname',
          mandatory: true,
          value: contact ? contact.firstname : '',
          label: getLabels().contacts.form.fields.firstName.label,
        },
        {
          type: 'inputModal',
          subType: 'text',
          name: 'lastname',
          mandatory: true,
          value: contact ? contact.lastname : '',
          label: getLabels().contacts.form.fields.lastName.label,
        },
        {
          type: 'inputModal',
          subType: 'text',
          name: 'country',
          mandatory: true,
          value: contact ? contact.country : '',
          label: getLabels().contacts.form.fields.city.label,
        },
        {
          type: 'inputModal',
          subType: 'text',
          name: 'organisation',
          mandatory: true,
          value: contact ? contact.organisation : '',
          label: getLabels().contacts.form.fields.organizationName.label,
        },
        {
          type: 'inputModal',
          subType: 'text',
          name: 'position',
          value: contact ? contact.position : '',
          label: getLabels().contacts.form.fields.role.label,
        },
        {
          type: 'inputModal',
          subType: 'email',
          mandatory: true,
          name: 'email',
          value: contact ? contact.email : '',
          label: getLabels().contacts.form.fields.email.label,
        },
        {
          type: 'inputModal',
          subType: 'text',
          name: 'phone',
          value: contact ? contact.phone : '',
          label: getLabels().contacts.form.fields.mobilePhone.label,
        },
      ],
    },
    saveContact,
    null,
    null
  );
}

export function getContact(idSelected) {
  const contacts = Query.getAll(DATA_TYPE_USERS);
  const existingContact = contacts.find((_contact) => {
    if (idSelected && _contact.id === parseInt(idSelected, 10)) {
      return _contact;
    }
  }, {});
  return existingContact;
}

export function SelectContact(id) {
  openContactForm(getContact(id));
}

/**
 * Add or update a contact
 * @param  {object} contact
 */
export function saveContact(contact, skipSave) {
  if (contact.email && contact.email.split('@') && contact.email.split('@')[0] == '')
    contact.email = '';
  const contacts = Query.getAll(DATA_TYPE_USERS);
  const existingIndex = getContact(contact.id);

  if (existingIndex !== -1) {
    // Update contact
    contacts.splice(existingIndex, 1, contact);
  } else {
    // Push contact
    contacts.push(contact);
  }
  if (!skipSave) {
    saveContact(contacts);
  }
}

export function onSendRequest(payload, favorites) {
  let userData = TexShowPersistence.getUserData();
  userData = config.texShow.userDataBackend(userData);
  let products =
    favorites && favorites.newproducts && payload && payload.relatedExhibitorsProduct
      ? getItemProduct(favorites.newproducts, payload.relatedExhibitorsProduct)
      : [];

  if (products.length === 0) {
    getBindedActions().showNotification({
      message: getLabels().texShow.emptyProduct,
      level: NotificationLevels.ERROR,
    });
  } else if (
    !isNonNullObject(userData) ||
    !isNonNullObject(payload.exhibitor) ||
    !isSessionValid() ||
    getProfile() !== VISITOR_PROFILE
  ) {
    getBindedActions().showNotification({
      message: getLabels().texShow.serverError,
      level: NotificationLevels.ERROR,
    });
  } else {
    let data = {
      user: userData,
      note: payload.note,
      exhibitor: payload.exhibitor,
      products,
      key: config.texShow.KEY,
    };
    showConfirmModal({
      title: getLabels().common.contact,
      text: getLabels().texShow.ConfirmMessage,
      yesBtnLabel: getLabels().common.yes,
      noBtnLabel: getLabels().common.no,
      yesCb: function() {
        performSendRequest(data);
      },
      noCb: () => {},
    });
  }
}

export function resetUserData() {
  setProfile(DESK_PROFILE);
  // -wipe out of user-data
  TexShowPersistence.clearUserData();

  global.isAuthentificateWithCode = null;
  // wipe out all of favourites
  FavoritesPersistence.removeAll();
  // -wipe out of notes
  NotesPersistence.removeAll();

  UserDataService.clear();
  getBindedActions().showNotification({
    message: getLabels().texShow.resetSuccess,
    level: NotificationLevels.SUCCESS,
  });
}

export function performSendRequest(payload) {
  const opts = {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: [HEADERS.JSON_CONTENT_TYPE],
  };

  fetchHelper(
    config.texShow.REQUEST_URL,
    opts,
    true, // parse response as json
    function onSuccess(data) {
      if (data && data.error) {
        getBindedActions().showNotification({
          message: getLabels().texShow.erreurMessage,
          level: NotificationLevels.ERROR,
        });
      } else if (data && data.success) {
        getBindedActions().showNotification({
          message: getLabels().texShow.sendSuccess,
          level: NotificationLevels.SUCCESS,
        });
      }
    },
    function onFailure(error, reason) {
      console.error('TexShow onFailure::', error, 'Message Error:', reason);
      getBindedActions().showNotification({
        message: getLabels().texShow.serverError,
        level: NotificationLevels.ERROR,
      });
    },
    false // showModalOnError
  );
}

export function getItemProduct(fav = [], favRelatedToExhibitor = []) {
  let product = [];
  favRelatedToExhibitor.map((item) => {
    fav.map((element) => {
      if (item.id === element) product.push(item);
    });
  });
  return product;
}

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function updateFieldUserData(name, value) {
  var userData = TexShowPersistence.getUserData();

  showPromptModal({
    title: getLabels().texShow.titleCodeRequired,
    yesBtnLabel: getLabels().common.confirm,
    noBtnLabel: getLabels().common.cancel,
    validateCb: (input) => {
      if (typeof input !== 'string') {
        // user probably cancelled the action (modal discarded)
        // ignore
      } else if (TexShowPersistence.checkAuthentificateCode(input)) {
        showPromptModal({
          title: getLabels().userData.fields[name].label,
          inputValue: value && value !== '' ? value : '',
          inputPlaceholder: getLabels().userData.fields[name].placeholder,
          yesBtnLabel: getLabels().common.save,
          noBtnLabel: getLabels().common.cancel,
          validateCb: (val) => {
            if (typeof val !== 'string') {
              // user probably cancelled the action (modal discarded)
              // ignore
            } else if (
              (name === 'firstName' ||
                name === 'lastName' ||
                name === 'email' ||
                name === 'organizationName') &&
              val === ''
            ) {
              getBindedActions().showNotification({
                message: getLabels().contacts.form.fields[name].validationMessage,
                level: NotificationLevels.ERROR,
              });
            } else if (name === 'email' && !validateEmail(val)) {
              getBindedActions().showNotification({
                message: getLabels().contacts.form.fields['email'].validationMessage,
                level: NotificationLevels.ERROR,
              });
            } else {
              userData[name] = val && val !== '' ? val : value && value !== '' ? value : '';
              getBindedActions().userDataUpdated(userData);
              TexShowPersistence.setUserData(userData);
            }
          },
          noCb: global.noop,
        });
      } else {
        getBindedActions().showNotification({
          message: getLabels().profile.invalidCode,
          level: NotificationLevels.ERROR,
        });
      }
    },
    noCb: global.noop,
  });
}

// Data update request
export function reloadData(showNotif) {
  Updater.startUpdate(true);
  clearPersistedData(function(err) {
    if (err) {
      // ignored
    }
    DataAssetsUtil.init();
  });
  
  if (showNotif) {
    getBindedActions().showNotification({
      message: getLabels().texShow.updateSuccess,
      level: NotificationLevels.SUCCESS,
    });
  }
}

// Data update request
export function reloadDataWhenLogged() {
  Updater.init(reloadData(false));
  TexShowPersistence.clearIsUpdated();
}
