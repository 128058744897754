import { USER_PAGE_KEY } from 'src/pages/pagesKeys';
const { DESK_PROFILE } = require('../../../data/config/profiles');
import { isSessionValid } from 'src/core/login/LoginService';
import GenericItemPage, { DOM_ID } from 'src/pages/generic-item-page/GenericItemPage';

import UserContent from './UserContent';
import { get as getProfile } from 'src/core/Profile';

export default {
  key: USER_PAGE_KEY,
  path: '/user',
  elId: DOM_ID,
  className: 'user-page',
  component: GenericItemPage,
  childComponent: UserContent,
  relatedDataToFetch: [],
  isGranted: () => !isSessionValid(),
};
