import {
  SET_LOGIN_TEXSHOW_STATUS,
  ADD_NEW_CONTACT,
  SEND_EMAIL_REQUEST,
  RESET_DATA_TEXSHOW,
  RELOAD_DATA_TEXSHOW,
  HAS_NAVIGATED,
} from 'src/store/actionTypes';

import {
  onUserLogIn,
  addNewContact,
  onSendRequest,
  resetUserData,
  reloadData,
  reloadDataWhenLogged,
} from './TexShowData';

import * as TexShowPersistence from './TexShowPersistence';

import { isSessionValid } from 'src/core/login/LoginService';

export default ({ dispatch, getState }) => (next) => (action) => {
  const result = next(action);

  switch (action.type) {
    case SET_LOGIN_TEXSHOW_STATUS:
      onUserLogIn(action.userDataTexShow, action.errorChoosedProfile);
      break;
    case ADD_NEW_CONTACT:
      addNewContact();
      break;
    case SEND_EMAIL_REQUEST:
      onSendRequest(action.payloadRequestedEmail, action.favorites);
      break;
    case RESET_DATA_TEXSHOW:
      resetUserData();
      break;
    case RELOAD_DATA_TEXSHOW:
      reloadData(true);
    case HAS_NAVIGATED: {
      if (
        isSessionValid() &&
        TexShowPersistence.getIsUpdated() &&
        action &&
        action.pageKey === 'List' &&
        action.pageProps &&
        action.pageProps.inputs &&
        action.pageProps.inputs[0] &&
        action.pageProps.inputs[0].dataType === 'exhibitors'
      ) {
        reloadDataWhenLogged();
      }
    }
    default: // for linter
  }

  return result;
};
