var _defineProperty = require("/Users/mohamedbenkhedher/Documents/react/mspot-texshow-2021/app-react/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

var _LOGIN;

// NB: to build a cordova app, relative path is needed here
var _require = require('../../src/pages/pagesKeys'),
    HOME_PAGE_KEY = _require.HOME_PAGE_KEY,
    CONTACT_PAGE_KEY = _require.CONTACT_PAGE_KEY,
    EXHIBITOR_PAGE_KEY = _require.EXHIBITOR_PAGE_KEY,
    LIST_PAGE_KEY = _require.LIST_PAGE_KEY;

var _require2 = require('../../src/core/webservices/WsProviders'),
    TAIGA = _require2.TAIGA;

var projectConfig = require('../../../data/config.js');

var _require3 = require('./profiles'),
    DESK_PROFILE = _require3.DESK_PROFILE,
    VISITOR_PROFILE = _require3.VISITOR_PROFILE;

var _require4 = require('./dataConfig'),
    DATA_TYPE_EVENT_CATEGORIES = _require4.DATA_TYPE_EVENT_CATEGORIES,
    DATA_TYPE_EVENTS = _require4.DATA_TYPE_EVENTS,
    DATA_TYPE_EXHIBITOR_CATEGORIES = _require4.DATA_TYPE_EXHIBITOR_CATEGORIES,
    DATA_TYPE_EXHIBITORS = _require4.DATA_TYPE_EXHIBITORS,
    DATA_TYPE_USERS = _require4.DATA_TYPE_USERS;

var codificationsConfig = require('./codificationsConfig'); // Only export a default object, to be able to
// override properties depending on target environment


var BO_TAIGA_URL = 'https://pgorganisation.site.calypso-event.net';
var KLIPSO_LEADS_WS_ROOT = 'http://www.e-registration.net/WebServices/WS_Gaia_Mobile/GaiaMobile';
var NODE_BACKEND_DOMAIN = 'https://texshow2021-node-backend-dev.mobile-spot.com'; // 'http://localhost:3075'; //

module.exports = {
  EVENT_NAME: 'TEXSHOW 2021',
  // Misc UI
  DELAY_BEFORE_CLICK_ACTION: 50,
  // ms
  SHOW_TERMS_OF_USE_ON_HOME: false,
  // Terms of use URL
  TOU_LINK: {
    en: '',
    // TODO
    fr: '' // TODO

  },
  TOU_LINK_TARGET: '_blank',
  TOU_LINK_OPENININAPPBROWSER: true,
  // App profiles
  SUPPORTED_PROFILES: [DESK_PROFILE, VISITOR_PROFILE],
  DEFAULT_PROFILE: DESK_PROFILE,
  // Routing (in two columns mode, = default page on left column)
  getHomePage: function getHomePage(profile) {
    switch (profile) {
      case DESK_PROFILE:
        return {
          pageKey: LIST_PAGE_KEY,
          props: {
            //hasFilter: true,
            inputs: [{
              dataType: DATA_TYPE_USERS
            }]
          }
        };

      case VISITOR_PROFILE:
        return {
          pageKey: LIST_PAGE_KEY,
          props: {
            inputs: [{
              dataType: DATA_TYPE_EXHIBITORS
            }]
          }
        };

      default:
        console.error("Bad configuration, unexpected profile: ".concat(profile));
    }
  },
  APP_TITLE_SEPARATOR: ' - ',
  ROUTE_SEPARATOR: '?',
  // Lang
  SUPPORTED_LANGS: ['fr', 'en'],
  DEFAULT_LANG: 'en',
  // Backend / Updates
  UPDATE_ENABLED: false,
  BO_SSH_USER: 'www',
  // Map
  MOBIGEO_API_KEY: 'chaach5Aem',
  // Mobile-Spot link
  // MOBILESPOT_WWW: 'http://www.mobile-spot.com',
  // SHARE:
  DEFAULT_SHARE_IMAGE: '',
  //  on LinkedIn
  LINKEDIN_API_KEY: '',
  //  on Facebook
  FACEBOOK_APP_ID: '',
  WEB_URL: projectConfig.web.url,
  // In web mode, this fallback url can be used if the user's
  // browser does not meet some requirements (eg IE<11)
  WEB_FALLBACK: '',
  // e.g com.printemps.haussmann.dev
  NATIVE_ID: projectConfig.native.id,
  // Note: handling urls depending on environment is welcomed.
  // in that case, declare this variable in config-[env].js
  ADDITIONAL_DEFAULTSRC_CSP: [KLIPSO_LEADS_WS_ROOT, BO_TAIGA_URL],

  /**
   * FEATURES
   */
  DOWNLOAD_FAVORITES: {
    FEATURE_ENABLED: false
  },
  APPOINTMENT_REQUEST: {
    FEATURE_ENABLED: false // webservice url is defined per environment

  },
  CONTRIBUTIONS_FEED: {
    FEATURE_ENABLED: false
  },
  FLIGHTS_SCHEDULE: {
    FEATURE_ENABLED: false
  },
  LOGIN: (_LOGIN = {
    FEATURE_ENABLED: false,
    displayLabel: true,
    PROVIDER: '',
    //TAIGA,
    forgottenPasswordURL: "".concat(BO_TAIGA_URL, "/mobile-se/mot-de-passe-oublie.htm")
  }, _defineProperty(_LOGIN, "forgottenPasswordURL", null), _defineProperty(_LOGIN, "forgottenPasswordURLTarget", '_blank'), _defineProperty(_LOGIN, "forgottenPasswordOpenInInAppBrowser", true), _defineProperty(_LOGIN, "createAccountURL", null), _defineProperty(_LOGIN, "createAccountURLTarget", '_blank'), _defineProperty(_LOGIN, "createAccountOpenInInAppBrowser", true), _defineProperty(_LOGIN, "tosURL", null), _defineProperty(_LOGIN, "tosURLTarget", '_blank'), _LOGIN),
  BO_TAIGA_WS_URL: "".concat(BO_TAIGA_URL, "/tgServices/pgPromoAppWs/SantExpo"),
  POLL: {
    FEATURE_ENABLED: false
  },
  USER_DATA: {
    FEATURE_ENABLED: true,
    PROVIDER: '',
    SECTIONS: [{
      className: '',
      label: '',
      displayLabel: true,
      fields: {
        /*  profilePicture: {
           type: 'image',
           editable: true,
         }, */
        title: {
          type: 'text',
          required: false,
          editable: true
        },
        firstName: {
          type: 'text',
          required: true,
          editable: true
        },
        lastName: {
          type: 'text',
          required: true,
          editable: true
        },
        city: {
          type: 'text',
          required: false,
          editable: true
        },
        organizationName: {
          type: 'text',
          required: true,
          editable: true
        },
        position: {
          type: 'text',
          required: false,
          editable: true
        },
        email: {
          type: 'email',
          required: true,
          pattern: /^[\w._+-]+@[\w.-]+\.[A-Za-z]{2,64}$/,
          editable: true
        },
        phone: {
          type: 'tel',
          required: false,
          pattern: /^[ \.\+0-9]*$/,
          editable: true
        }
      }
    } // {
    //   className: 'ud-section-switches',
    //   label: 'userData.notice', // i18n path
    //   fields: {
    //     contactable: {
    //       type: 'bool',
    //       required: true,
    //       editable: true,
    //     },
    //   },
    // },
    ],
    externalResourceLinkTarget: '_blank',
    externalResourceLinkOpenInInAppBrowser: true
  },
  IMAGE_UPLOAD: {
    // see https://github.com/blueimp/JavaScript-Load-Image#options
    LOAD_IMAGE_OPTIONS: {
      maxWidth: 900,
      maxHeight: 900,
      contain: false,
      orientation: true,
      canvas: true
    },
    INPUT_ACCEPTED_MIME: 'image/*',
    OUTPUT_MIME: 'image/jpeg',
    OUTPUT_COMPRESSION: 0.92,
    DISPLAY_IMAGE_OPTIONS: {
      maxWidth: 300,
      maxHeight: 300,
      cropMinWidth: 100,
      cropMinHeight: 100,
      cropInitialWidth: 300,
      cropRatio: 0.85
    }
  },
  KLIPSO_LEADS: {
    HAS_HEADER_CLOSE_BUTTON: false,
    LABEL_INSTEAD_OF_ICON: true,
    LICENCE_SCAN_ACCEPTED_FORMATS: 'QR_CODE',
    LICENCE_REGEXP: /^[A-Za-z0-9]{10}$/,
    TOS_LINKS: {
      fr: 'https://www.leni.fr/mentions/klipsoleads-protection-des-donnees.htm',
      en: 'https://www.leni.fr/en/mentions/klipsoleads-data-protection.htm'
    },
    CHECK_LICENCE_WS_URL: "".concat(KLIPSO_LEADS_WS_ROOT, "/AuthenticateExhibitorAppByCAB"),
    REGISTER_CHECKPOINT_WS_URL: "".concat(KLIPSO_LEADS_WS_ROOT, "/RegisterCheckPoint"),
    GET_FORM_FIELDS_WS_URL: "".concat(KLIPSO_LEADS_WS_ROOT, "/GetFormFields"),
    SAVE_WS_URL: "".concat(KLIPSO_LEADS_WS_ROOT, "/SaveVisitorRelationList")
  },
  NETWORKING: {
    FEATURE_ENABLED: false,
    PROVIDER: TAIGA,
    PARTICIPANTS_DATA_MODE: 'PARTIAL',
    // 'ALL' or 'PARTIAL' - see SAN-40
    ALL_PARTICIPANTS_DATA_EXPIRE_DELAY: 1 * 60 * 1000,
    FETCH_EXHIBITOR_PARTICIPANTS: true,
    FETCH_SPEAKER_PARTICIPANT: true
  },
  SEARCH_TAIGA: {
    WS_PARAMS: {
      start: 0,
      take: 100
    },
    SIMPLE_SECTIONS: [{
      className: '',
      label: '',
      displayLabelInput: true,
      fields: {
        keyword: {
          type: 'text',
          label: 'searchTaiga.fields.search.label',
          placeholder: 'searchTaiga.fields.search.placeholder',
          hasSearchButton: true
        }
      }
      /*buttons: [
        {
          key: 'simple-search-toggle-btn', // mandatory
          type: 'TOGGLE',
          defaultVisibleButtonIndex: 0, // optional (default: 0)
          content: [
            {
              label: 'searchTaiga.fieldToggle.byCompany',
              field: {
                organizationName: {
                  type: 'text',
                  label: 'searchTaiga.fields.company.label',
                  placeholder: 'searchTaiga.fields.company.placeholder',
                  hasSearchButton: true,
                },
              },
            },
            {
              label: 'searchTaiga.fieldToggle.byName',
              field: {
                lastName: {
                  type: 'text',
                  placeholder: 'searchTaiga.fields.name.placeholder',
                  label: 'searchTaiga.fields.name.label',
                  hasSearchButton: true,
                },
              },
            },
          ],
        },
      ],*/

    }],
    ADVANCED_SECTIONS: [{
      className: '',
      label: '',
      displayLabelInput: true,
      displayIcon: false,
      // displayIcon for displaying icon on form input
      fields: {
        lastName: {
          type: 'text-modal',
          label: 'searchTaiga.fields.name.label',
          placeholder: 'searchTaiga.fields.name.placeholder',
          icon: 'fal fa-user-circle'
        },
        organizationName: {
          type: 'text-modal',
          label: 'searchTaiga.fields.company.label',
          placeholder: 'searchTaiga.fields.company.placeholder',
          icon: 'fal fa-tag fa-flip-horizontal' // displayIcon:true for displaying this icon

        },
        role: {
          type: 'checkbox-modal',
          multiple: false,
          label: 'searchTaiga.fields.role.label',
          placeholder: 'searchTaiga.fields.role.placeholder',
          icon: 'fal fa-cog',
          // TODO
          values: {
            codif: codificationsConfig.CODIFICATION_FONCTION
          }
        },
        profil: {
          type: 'checkbox-modal',
          multiple: false,
          label: 'searchTaiga.fields.profil.label',
          placeholder: 'searchTaiga.fields.profil.placeholder',
          icon: 'fal fa-cog',
          // TODO
          values: {
            codif: codificationsConfig.CODIFICATION_PROFIL
          }
        },
        service: {
          type: 'checkbox-modal',
          multiple: false,
          label: 'searchTaiga.fields.service.label',
          placeholder: 'searchTaiga.fields.service.placeholder',
          icon: 'fal fa-cog',
          // TODO
          values: {
            codif: codificationsConfig.CODIFICATION_SERVICE
          }
        },
        centerinterest: {
          type: 'checkbox-modal',
          multiple: true,
          label: 'searchTaiga.fields.centerinterest.label',
          placeholder: 'searchTaiga.fields.centerinterest.placeholder',
          icon: 'fal fa-cog',
          // TODO
          values: {
            codif: codificationsConfig.CODIFICATION_CENTREINTERET
          }
        }
      }
    }]
  },
  SHARE: {
    FEATURE_ENABLED: false,
    POSITION_SHARE_IMAGE: '',
    // Declare restrictions
    // If current page + current profile are found below,
    // then share button is hidden.
    RESTRICTIONS: [// e.g
      // { pageKey: EXHIBITOR_PAGE_KEY,
      //   for : [ VISITOR_GP_PROFILE ]
      // }
    ]
  },
  NOTES: {
    FEATURE_ENABLED: true,
    RESTRICTIONS: []
  },
  CONTACTS: {
    FEATURE_ENABLED: false,
    RESTRICTIONS: [{
      pageKey: CONTACT_PAGE_KEY,
      for: [DESK_PROFILE]
    } // {
    //   pageKey: PARTICIPANT_PAGE_KEY,
    //   for: [EXHIBITOR_PROFILE],
    // },
    // {
    //   pageKey: USER_DATA_PAGE_KEY,
    //   for: [EXHIBITOR_PROFILE],
    // },
    ],
    SAVE_CONTACT_TO_DEVICE: {
      FEATURE_ENABLED: false,
      RESTRICTIONS: [// {
        //   pageKey: CONTACT_PAGE_KEY,
        //   for: [EXHIBITOR_PROFILE],
        // },
        // {
        //   pageKey: PARTICIPANT_PAGE_KEY,
        //   for: [EXHIBITOR_PROFILE],
        // },
        // {
        //   pageKey: USER_DATA_PAGE_KEY,
        //   for: [EXHIBITOR_PROFILE],
        // },
      ]
    }
  },
  SYNCHRONIZED_FAVORITES: {
    FEATURE_ENABLED: false,
    PUBLIC_KEY: 'eN/sCwOHNN+42fjwDoLj35xaVN0OgnXsxzV8+yvu0oy0Ea3Klk19j3EEN3uPZkw3HR8HlEpOSg999rsBwwklDv/BoRNt8RhYZoCyDfmcfhusV/w8go7jtw6JI4q4P8nfB6QgotAfpAYG/ijCOzbbXI/WG1uppA2DP8R6vb7d7WM=',
    UNAUTHORIZED_MODE_ENABLED: true,
    // webservice url is defined per environment
    // Debouncing value represents the delay between
    // last favorite toggle (add or remove) and server call
    DEBOUNCING: 10 * 1000,
    // ms
    MODE: 'peering' // (peering or login)

  },
  SOCIAL: {
    FEATURE_ENABLED: false
  },
  CODEPUSH: {
    FEATURE_ENABLED: false
  },
  EXPORT_EVENT: {
    FEATURE_ENABLED: false
  },
  SYNOPTIC_AGENDA: {
    AUTOFOCUS_TAB: false,
    SYNOPTIC_DEFAULT_EVENT_BGCOLOR: '#404040',
    DEFAULT_INPUT: {
      agendaProps: {
        input: {
          parentId: 12,
          parentType: DATA_TYPE_EVENT_CATEGORIES,
          dataType: DATA_TYPE_EVENTS
        }
      }
    }
  },
  SSO: {
    FEATURE_ENABLED: false,
    SSO_REQUEST_URL: "".concat(NODE_BACKEND_DOMAIN, "/sso-request"),
    SSO_WEB_LINK_TARGET: '_self',
    userData: function userData(user) {
      return {
        profilePicture: user.photo ? user.photo : '',
        title: user.title ? user.title : '',
        firstName: user.firstname ? user.firstname : '',
        lastName: user.lastname ? user.lastname : '',
        position: user.position ? user.position : '',
        login: user.login ? user.login : '',
        email: user.email ? user.email : '',
        phone: user.phone ? user.phone : '',
        autoLink: user.id ? user.id : ''
      };
    }
  }
};