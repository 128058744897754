import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { SEARCH_PAGE_KEY } from 'src/pages/pagesKeys';

import AppToolbar from 'src/components/app-toolbar/AppToolbar';
import Menu from 'src/components/menu/Menu';
import SearchPageContent from './SearchPageContent';

import * as actions from 'src/store/actions';

import './SearchPage.scss';

const LOG_PREF = '[SearchPage] ';


class SearchPage extends Component {
  constructor(props) {
    super(props);
    this.pageKey = SEARCH_PAGE_KEY;

    this.performSearch = this.performSearch.bind(this);
  }

  componentDidMount() {
    this.setDocumentContext();
  }

  componentDidUpdate() {
    this.setDocumentContext();
  }

  /**
   * String displayed in app toolbar
   * @return {string}
   */
  getPageTitle() {
    return this.props.labels.search.title;
  }

  /**
   * Update string visible in browser tab/history/favorites
   */
  setDocumentContext() {
    if (this.props.isActive(this.pageKey)) {
      this.props.setDocumentContext(this.getPageTitle());
    }
  }

  shouldComponentUpdate({ relaunch }) {
    // relaunch === true when data has been updated (including on language switch)
    if (this.props.relaunch !== relaunch && relaunch === true) {
      this.performSearch(this.props.searched);
      return false;
    }
    return true;
  }

  performSearch(str){
    this.props.actions.performSearch(str, SEARCH_PAGE_KEY);
  }

  render() {
    console.log(LOG_PREF + 'render');

    // Avoid resetting contextual search field
    if (!this.props.isVisible && !this.props.searched) {
      return null;
    }

    const {
      actions,
      adConfig,
      backButtonInToolbar,
      favButtonInToolbar,
      favIconDisabled,
      favorites,
      hasToolbar,
      homeButtonInToolbar,
      isLocationEnabled,
      isPending,
      keyboardHeight,
      labels,
      menuButtonInToolbar,
      menuOptions,
      placeholder,
      profile,
      results,
      searchButtonInToolbar,
      searched,
      twocolumns,
      userData,
    } = this.props;

    return (
      <>
        <Menu
          options={menuOptions}
          actions={actions}
          labels={labels}
          profile={profile}
          associatedPageKey={this.pageKey}
          adConfig={adConfig}
          twoColumns={twocolumns}
          isLocationEnabled={isLocationEnabled}
        />

        <AppToolbar
          labels={labels}
          isDisplayed={hasToolbar}
          actions={actions}
          title={this.getPageTitle()}
          pageKey={this.pageKey}
          profile={profile}
          hasBackButton={backButtonInToolbar}
          hasHomeButton={homeButtonInToolbar}
          hasFavButton={favButtonInToolbar}
          hasSearchButton={searchButtonInToolbar}
          listToolBarButton={this.props.listToolBarButton}
          hasMenuButton={menuButtonInToolbar}
          disableDropShadow
        />

        <SearchPageContent
          isPending={isPending}
          placeholder={placeholder}
          favIconDisabled={favIconDisabled}
          favorites={favorites}
          performSearch={this.performSearch}
          searched={searched}
          results={results}
          userData={userData}
          keyboardHeight={keyboardHeight}
          actions={actions}
          labels={labels}
        />
      </>
    );
  }
}

SearchPage.propTypes = {
  placeholder: PropTypes.string,
  isPending: PropTypes.bool,
  relaunch: PropTypes.bool,
  results: PropTypes.object,
  favorites: PropTypes.object,
  favIconDisabled: PropTypes.bool,
  userData: PropTypes.object,
  // Common page props
  listToolBarButton: PropTypes.func,
  menuOptions: PropTypes.object.isRequired,
  profile: PropTypes.string,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  isActive: PropTypes.func.isRequired,
  queryString: PropTypes.string.isRequired,
  setDocumentContext: PropTypes.func.isRequired,
  isLocationEnabled: PropTypes.bool,
  keyboardHeight: PropTypes.number,
  isVisible: PropTypes.bool, // set by togglePageAfterNavigation common reducer function
  // toolbar
  hasToolbar: PropTypes.bool,
  homeButtonInToolbar: PropTypes.bool,
  backButtonInToolbar: PropTypes.bool,
  searchButtonInToolbar: PropTypes.bool,
  favButtonInToolbar: PropTypes.bool,
  menuButtonInToolbar: PropTypes.bool,
};

const mapStateToProps = state => state[SEARCH_PAGE_KEY];
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);
