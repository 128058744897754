import { getBindedActions } from 'src/store/bindedActions';
import { get as getLabels } from 'src/core/Lang';
import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels';

const LOG_PREF = '[FetchHelper] ';

const DEFAULT_FETCH_TIMEOUT = 15000;

const FETCH_ERRORS = {
  REQUEST: 'Request failed',
  PARSE: 'Failed to parse json',
};

export const HEADERS = {
  JSON_CONTENT_TYPE: { name: 'Content-Type', value: 'application/json' },
};

/**
 * Common error handler
 * @param {string}   msg
 * @param {function} cb
 * @param {boolean} showModalOnError (optional)
 * @param {...[*]}   args
 */
function errorHandler(msg, cb, showModalOnError, ...args) {
  //console.error(LOG_PREF + msg, args);

  // UI display error
  if (showModalOnError !== false) {
    getBindedActions().showNotification({
      message: getLabels().common.fetchError,
      level: NotificationLevels.ERROR,
    });
  }

  if (typeof cb === 'function') {
    cb(msg);
  }
}

/**
 * Handle non-JSON requests
 * @param {object} promise
 * @param {string} url (used for error logging only)
 * @param {function} successCb (optional)
 * @param {function} errorCb   (optional)
 * @param {boolean} showModalOnError (optional)
 */
function commonHandler(promise, url, successCb, errorCb, showModalOnError) {
  promise.then(
    (response) => {
      if (response.status >= 400) {
        errorHandler(
          `${FETCH_ERRORS.REQUEST} (${response.status})`,
          errorCb,
          showModalOnError,
          url
        );
      } else if (typeof successCb === 'function') {
        // Allows any exception raised in callback to not be caught by promise
        window.setTimeout(successCb, 1, response);
      }
    },
    // fetch failure
    (...args) => {
      errorHandler(FETCH_ERRORS.REQUEST, errorCb, showModalOnError, url, ...args);
    }
  );
}

/**
 * Handle JSON requests
 * @param {object} promise
 * @param {string} url (used for error logging only)
 * @param {function} successCb (optional)
 * @param {function} errorCb   (optional)
 * @param {boolean} showModalOnError (optional)
 */
function jsonHandler(promise, url, successCb, errorCb, showModalOnError) {
  promise
    .then(
      (response) => {
        if (response.status >= 400) {
          throw FETCH_ERRORS.REQUEST;
        } else {
          return response.json();
        }
      },
      // fetch failure
      (...args) => {
        throw FETCH_ERRORS.REQUEST;
      }
    )
    .then(
      // parse success
      (json) => {
        if (json) {
          if (typeof successCb === 'function') {
            // Allows any exception raised in callback to not be caught by promise
            window.setTimeout(successCb, 1, json);
          }
        } else {
          throw FETCH_ERRORS.PARSE;
        }
      },
      // parse failure
      (...args) => {
        throw FETCH_ERRORS.PARSE;
      }
    )
    .catch(function(reason) {
      errorHandler(reason, errorCb, showModalOnError, url);
    });
}

function fetchUsingHttpPlugin(url, opts) {
  return new Promise(function(resolve, reject) {
    var options;
    if (
      opts.method.toUpperCase() === 'GET' &&
      opts.headers &&
      opts.headers[1] &&
      opts.headers[1].name === 'Authorization'
    ) {
      options = {
        method: 'GET',
        data: opts.body ? JSON.parse(opts.body) : null,
        headers: { 'Authorization': opts.headers[1].value }
      };
    } else {
      options = {
        method: opts.method || 'GET',
        data: opts.body ? JSON.parse(opts.body) : null,
      };
    }
    
    //set Data Serializer for Android
    if (
      opts.method.toUpperCase() === 'POST' &&
      opts.headers &&
      opts.headers[0] &&
      opts.headers[0].value === 'application/json'
    ) {
      window.cordova.plugin.http.setDataSerializer('json');
    } else {
      window.cordova.plugin.http.setDataSerializer('urlencoded');
    }
    
    window.cordova.plugin.http.sendRequest(
      url,
      options,
      function(response) {
        resolve(new Response(response.data, { status: response.status || 200 }));
      },
      function({ error }) {
        reject(new TypeError('HTTP request error'), error);
      }
    );
  });
}

/**
 * Handle response for relative path in cordova app, and file:// protocol
 * @param  {string} url
 * @param  {object} opt (optional), currently handled options are: method, body, timeout
 */
function fetchUsingXhr(url, opt) {

  return new Promise(function(resolve, reject) {
    const hasOptions = typeof opt === 'object' && opt !== null;

    const xhr = new XMLHttpRequest();
    xhr.onload = function() {
      
      // On Safari status 0 (undocumented HTTP code) is returned when everything is fine
      resolve(new Response(xhr.responseText, { status: xhr.status || 200 }));
    };
    xhr.ontimeout = function(e) {
      reject(new TypeError('XHR request timeout'), e);
    };
    xhr.onabort = function(e) {
      reject(new TypeError('XHR request aborted'), e);
    };
    xhr.onerror = function(e) {
      reject(new TypeError('XHR request failed'), e);
    };

    const method = (hasOptions && opt.method) || 'GET';
    xhr.open(method, url);

    // Beware
    // On IE 11, options should be set after the call to 'open()', else an 'InvalidStateError' is thrown
    xhr.timeout =
      hasOptions && typeof opt.timeout === 'number' ? opt.timeout : DEFAULT_FETCH_TIMEOUT;

    // Set options
    if (hasOptions) {
      if (opt.headers != null) {
        opt.headers.forEach((header) => {
          xhr.setRequestHeader(header.name, header.value);
        });
      }
      if (opt.withCredentials) {
        xhr.withCredentials = true;
      }
    }
    
    xhr.send(opt && opt.body ? opt.body : null);
  });
}

/**
 * @param {string} url
 * @param {object} opt (optional) - request options (e.g method, timeout, headers, body)
 * @param {boolean} isJson (optional)
 * @param {function} successCb (optional)
 * @param {function} errorCb   (optional)
 * @param {boolean} showModalOnError (optional) - default: true
 */
export default function fetchHelper(
  url,
  opt,
  isJson,
  successCb,
  errorCb,
  showModalOnError,
  useHttpPlugin
) {
  let promise;

  

  // Check url (not empty, type string)
  if (!url || typeof url !== 'string') {
    //console.error(`${LOG_PREF}Invalid 'url' parameter`);
    if (typeof errorCb === 'function') {
      errorCb();
    }
    return;
  }

  //fetchUsingHttpPlugin(url; opt);
  // Fetch polyfill does not handle file:// protocol
  if (true) {
    // if ((global.isCordovaContext && url.startsWith('http') === false) || url.slice(0, 7) === 'file://' || isIOS()) {
    if (useHttpPlugin && global.isCordovaContext) {
      promise = fetchUsingHttpPlugin(url, opt);
    } else promise = fetchUsingXhr(url, opt);
  } else {
    // Default (web-service, rest api, etc) is supposed to be json
    promise = fetch(url, opt);
  }

  if (isJson) {
    jsonHandler(promise, url, successCb, errorCb, showModalOnError);
  } else {
    commonHandler(promise, url, successCb, errorCb, showModalOnError);
  }
}
