module.exports = require('deepmerge')(require('./config.default.js'), {
  version: {
    codepushDeploymentVersion: {
      iOS: "",
      android: ""
    }
  },
  native: {
    name: {
      default: 'TEXSHOW 2021_D'
    },
    id: 'com.mobilespot.texshow.dev',
    // iOS
    appleTeamId: '',
    // iosAppStoreUrl: 'https://itunes.apple.com/app/id1484463502',
    // Android
    // 'androidapp-id': 'TODO', // unused?
    playStoreUrl: ''
  },
  web: {
    url: 'texshow2021-dev.mobile-spot.com'
  }
});