import { LOGIN_PAGE_KEY } from 'src/pages/pagesKeys';

import LoginPage from './LoginPage';
import reducer from './loginPageReducer';

export default {
  key: LOGIN_PAGE_KEY,
  path: '/login',
  component: LoginPage,
  elId: 'login-page',
  getReduxReducer: (state, action) => reducer(state[LOGIN_PAGE_KEY], action),
  isGranted: false,
};
