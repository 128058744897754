var defaultConf = require('./config-default');

var BO_URL = 'texshow2021-data-dev.mobile-spot.com';
var BO_TAIGA_URL = 'https://pgorganisation.site.calypso-event.net';
var NODE_BACKEND_DOMAIN = 'https://texshow2021-node-backend-dev.mobile-spot.com'; // 'http://localhost:3075'; //

module.exports = require('deepmerge')(defaultConf, {
  ENV: 'dev',
  BO_URL: BO_URL,
  // no trailing '/'
  BO_PATH: '/home/www/messe-frankfurt-france/texworld/texshow2021/texshow2021-data-dev.mobile-spot.com',
  // no trailing '/'
  BO_SERVER_ALIAS: 'kim1',
  DEPLOY_PATH: '/home/www/messe-frankfurt-france/texworld/texshow2021/texshow2021-dev.mobile-spot.com',
  GOOGLE_ANALYTICS_ID: 'UA-48506479-32',
  // GA ID
  LOGIN: {
    forgottenPasswordURL: "".concat(BO_TAIGA_URL, "/mobile-se/mot-de-passe-oublie.htm")
  },
  projectId: '',
  // FCM sender id
  appId: '',
  // pushwoosh appid
  SEND_EXPORTED_NOTES_MAIL: {
    WS_URL: 'http://localhost:3096/api/exportNotesByMail',
    EXPEDITOR: 'no-reply@mobile-spot.com',
    KEY: 'zpgZkgiz845gjzFzi'
  },
  ADDITIONAL_DEFAULTSRC_CSP: (Array.isArray(defaultConf.ADDITIONAL_DEFAULTSRC_CSP) ? defaultConf.ADDITIONAL_DEFAULTSRC_CSP : []).concat([]),
  CHECK_NETWORK_URL: "https://".concat(BO_URL, "/online.txt"),
  SOCIAL: {
    FEATURE_ENABLED: false,
    TWITTER: {
      POST_PER_PAGE: 10
    },
    YOUTUBE: {
      POST_PER_PAGE: 5
    },
    URL: "https://".concat(NODE_BACKEND_DOMAIN, "/social")
  },
  texShow: {
    FEATURE_ENABLED: true,
    codeTexShow: "14071789",
    REQUEST_URL: "".concat(NODE_BACKEND_DOMAIN, "/information-request"),
    dbjsonFetchUrl: "".concat(NODE_BACKEND_DOMAIN, "/getdbJson"),
    KEYDB: 'kjdhlkdhjslkqdhqsid',
    KEY: 'kjdlkqsjdcnqslidjqs',
    userData: function userData(user) {
      return {
        //profilePicture: user.photo ? user.photo : '',
        title: user.civility ? user.civility : '',
        firstName: user.firstname ? user.firstname : '',
        lastName: user.lastname ? user.lastname : '',
        position: user.position ? user.position : '',
        organizationName: user.organisation ? user.organisation : '',
        email: user.email ? user.email : '',
        phone: user.phone ? user.phone : '',
        organizationWebsite: user.website ? user.website : '',
        login: user.login ? user.login : '',
        address: user.address ? user.address : '',
        city: user.country ? user.country : '',
        organizationLogo: user.logo ? user.logo : ''
      };
    },
    userDataBackend: function userDataBackend(user) {
      return {
        //profilePicture: user.photo ? user.photo : '',
        civility: user.title ? user.title : '',
        firstname: user.firstName ? user.firstName : '',
        lastname: user.lastName ? user.lastName : '',
        position: user.position ? user.position : '',
        organisation: user.organizationName ? user.organizationName : '',
        email: user.email ? user.email : '',
        phone: user.phone ? user.phone : '',
        website: user.organizationWebsite ? user.organizationWebsite : '',
        login: user.login ? user.login : '',
        address: user.address ? user.address : '',
        country: user.city ? user.city : '',
        logo: user.organizationLogo ? user.organizationLogo : ''
      };
    }
  }
});