import config from '../../../data/config/config';

const LOCALSTORAGE_USERDATA_KEY = 'userData';
const LOCALSTORAGE_UPDATE = 'update';

/**
 * UserData getter
 * @return {object}
 */
export function getUserData() {
  const data = JSON.parse(window.localStorage.getItem(LOCALSTORAGE_USERDATA_KEY));

  return data || null;
}

/**
 * UserData setter
 * @param {object}
 */
export function setUserData(value) {
  window.localStorage.setItem(LOCALSTORAGE_USERDATA_KEY, JSON.stringify(value));
}

/**
 * UserData  clear
 */
export function clearUserData() {
  window.localStorage.removeItem(LOCALSTORAGE_USERDATA_KEY);
}

/**
 * Code d'authenfication check
 * @param {string}
 * @return {boolean}
 */
export function checkAuthentificateCode(code) {
  return config.texShow.codeTexShow === code;
}

/**
 * update getter
 * @return {object}
 */
export function getIsUpdated() {
  const data = window.localStorage.getItem(LOCALSTORAGE_UPDATE)
    ? JSON.parse(window.localStorage.getItem(LOCALSTORAGE_UPDATE))
    : null;

  return data || null;
}

/**
 * update setter
 * @param {object}
 */
export function setIsUpdated(value) {
  window.localStorage.setItem(LOCALSTORAGE_UPDATE, JSON.stringify(value));
}

/**
 * update  clear
 */
export function clearIsUpdated() {
  window.localStorage.removeItem(LOCALSTORAGE_UPDATE);
}
