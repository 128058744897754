import config from 'data/config/config';

import * as UserDataService from 'src/core/user-data/UserDataService';

import * as LoginPersistence from 'src/core/login/LoginPersistence';
import { getParameterByName } from 'src/core/util/JsTools';
import { getBindedActions } from 'src/store/bindedActions';

import * as LoginWsNodeBackend from 'src/core/webservices/nodebackend/LoginWS';
import * as LoginWsTaiga from 'src/core/webservices/taiga/LoginWS';
import * as UserDataWsTaiga from 'src/core/webservices/taiga/UserDataWS';
import { TAIGA, NODEBACKEND } from 'src/core/webservices/WsProviders';
import { WS_ERRORS } from 'src/core/webservices/WS_ERRORS';
import showGenericWsErrorNotification from 'src/core/webservices/showGenericWsErrorNotification';
import * as KlipsoSsoPersistence from 'src/core/klipso-sso/KlipsoSsoPersistence';
import * as FavoritesPersistence from 'src/core/favorites/Favorites';
import * as NotesPersistence from 'src/core/notes/Notes';

import * as TexShowPersistence from 'src/core/tex-show/TexShowPersistence';
import * as TexShowService from 'src/core/tex-show/TexShowData';

const LOG_PREF = '[LoginService] ';

const QUERY_PARAM_TOKEN = 'token';
const QUERY_PARAMETER_KEY = 'infos';

export function isSessionValid() {
    return TexShowPersistence.getUserData() !== null /* && TexShowPersistence.isAuthentificateWithCode() */;
}

export function getUsername() {
  var userData = TexShowPersistence.getUserData();
  return userData ? ((userData.title ? userData.title : '' )+ ' ' + (userData.firstName ? userData.firstName : '') + ' ' + (userData.lastName ? userData.lastName : '')) : '';
}

let isLoggedIn = !!TexShowPersistence.getUserData();

export function setLoginStatus(_isLoggedIn, userData, error) {
  isLoggedIn = _isLoggedIn;
  getBindedActions().setLoginStatus(_isLoggedIn, userData, error);
}

/**
 * Inner-module responsible for AUTO-LOGIN
 */
const AutoLogin = (function() {
  let intervalId;

  function start() {
    if (intervalId) {
      // Already running
      return;
    }
    console.log(`${LOG_PREF} autologin: start`);
    intervalId = setInterval(relogin, config.LOGIN.autologinDelay * 1000);
  }

  function stop() {
    if (intervalId) {
      console.log(`${LOG_PREF} autologin: stop`);
      window.clearInterval(intervalId);
      intervalId = null;
    }
  }

  return {
    init,
    start,
    stop,
  };
})();

export function onUserLogIn() {
  if (typeof config.LOGIN.autologinDelay !== 'number') {
    console.warn(`${LOG_PREF}Autologin disabled ('autologinDelay' not specified or not a number)`);
  } else {
    AutoLogin.start();
  }
}

function logUnexpectedProvider() {
  //console.error(`${LOG_PREF}Unexpected web service provider: ${config.LOGIN.PROVIDER}`);
}

/**
 * Call login API if the user information is available
 */
function relogin() {
  if (isSessionValid()) {
    console.log(`${LOG_PREF}Autologin: about to renew token`);
    const account = TexShowPersistence.getUserData;
    if (!account) {
      logout();
    }
  }
  // switch (config.LOGIN.PROVIDER) {
  //   // case NODEBACKEND:
  //   //   if (isSessionValid()) {
  //   //     console.log(`${LOG_PREF}Autologin: about to renew token`);
  //   //     const account = LoginPersistence.getAccount();
  //   //     if (account) {
  //   //       login(account.username, account.password);
  //   //     }
  //   //   } else {
  //   //     logout();
  //   //   }
  //   //   break;

  //   // case TAIGA:
  //   //   if (LoginPersistence.getAccount() && LoginPersistence.getToken()) {
  //   //     UserDataWsTaiga.getUserData(function(error, userData) {
  //   //       if (!error && userData) {
  //   //         UserDataService.updateUserDataFromApiData(userData);
  //   //         setLoginStatus(true, userData, null);
  //   //       } else if (error === WS_ERRORS.AUTH) {
  //   //         setLoginStatus(null);
  //   //       } else if (error === WS_ERRORS.NETWORK) {
  //   //         showGenericWsErrorNotification(error);
  //   //       }
  //   //       // else ignore, keep the user logged-in
  //   //     });
  //   //   }
  //   //   break;

  //   default:
  //     logUnexpectedProvider();
  // }
}

export function init() {
  // First look for the token in query parameters
  // const userToken = getParameterByName(QUERY_PARAM_TOKEN);
  // const queryValue = getParameterByName(QUERY_PARAMETER_KEY);

  // if (config.SSO && config.SSO.FEATURE_ENABLED && queryValue) {
  //   getBindedActions().setLoginSSOStatus(true, queryValue, null);
  //   window.location.href = window.location.href.split('&' + QUERY_PARAMETER_KEY)[0];
  // }

  // if (userToken) {
  //   LoginPersistence.setToken(userToken);
  //   //UserDataService.refreshUserDataFromAPI();
  // } else {
  // else will look in localstorage (previous sessions)
  relogin();
  // }
}

function updateToken(value) {
  LoginPersistence.setToken(value);
  // TODO ?
  // getBindedActions().setLoginIdentification(value);
}

/**
 * Call the API to log the user in
 * @param  {string} username
 * @param  {string} password
 */
export function login(username, password) {
  const account = {
    username,
  };
  LoginPersistence.setAccount(account);

  account.password = password;

  // switch (config.LOGIN.PROVIDER) {
  //   // case NODEBACKEND:
  //   //   LoginWsNodeBackend.performLogin(account, loginCallback);
  //   //   break;

  //   // case TAIGA:
  //   //   LoginWsTaiga.performLogin(account, loginCallback);
  //   //   break;

  //   default:
  //     logUnexpectedProvider();
  // }
}

function loginCallback(errorCode, token, userData) {
  if (!errorCode) {
    updateToken(token);
    UserDataService.updateUserDataFromApiData(userData);
    setLoginStatus(true, userData, null);
  } else {
    switch (errorCode) {
      case WS_ERRORS.AUTH:
        updateToken(null);
        break;

      case WS_ERRORS.NETWORK:
      case WS_ERRORS.UNKNOWN:
        // TODO ?
        /* if (isSessionValid()) {
                    getBindedActions().setLoginIdentification(LoginPersistence.getToken());
                } */
        break;

      default:
        console.error(`${LOG_PREF}Unexpected error code: ${errorCode}`);
    }
    setLoginStatus(false, null, errorCode);
  }
}

/**
 * Set the user as logged out (no API call)
 */
export function logout() {
  TexShowService.checkCodeAuthenticate(false);
}

export function logOut() {
  updateToken(null);

  // Clear stored password

  const account = LoginPersistence.getAccount();
  if (account) {
    LoginPersistence.setAccount({ username: '' });
  }
  // -wipe out of user-data
  TexShowPersistence.clearUserData();
  // wipe out all of favourites
  FavoritesPersistence.removeAll();
  // -wipe out of notes
  NotesPersistence.removeAll();

  AutoLogin.stop();
  UserDataService.clear();
  setLoginStatus(false, null);
}
